<script>
import PageErrorTemplate from './PageErrorTemplate.vue';

export default {
  name: 'PageError404',
  components: { PageErrorTemplate },
};
</script>

<template>
  <PageErrorTemplate
    :error-name="$t('errors.error404.name')"
    :error-title="$t('errors.error404.title')"
    :error-subtitle="$t('errors.error404.subtitle')"
    :error-image-alt="$t('errors.error404.name')"
    error-image-src="/errors/404.svg"
  />
</template>

<style lang="scss" module></style>
